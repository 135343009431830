import { makeColor, makeShadesFromPalette } from '../utils';

const primaryColors = {
  brand: makeColor('hsla(141, 18%, 48%, 1)'),
  softGreen: makeColor('hsla(96, 14%, 93%, 1)'),
  neutralBeige: makeColor('hsla(48, 29%, 83%, 1)'),
  mediumBlue: makeColor('hsla(220, 49%, 82%, 1)'),
  darkBlue: makeColor('hsla(220, 48%, 62%, 1)'),
  mediumGreen: makeColor('hsla(113, 15%, 77%, 1)'),
  darkGreen: makeColor('hsla(158, 8%, 20%, 1)'),
  vibrantGreen: makeColor('hsla(126, 90%, 37%, 1)'),
  white: makeColor('hsla(0, 0%, 100%, 1)'),
};

const primaryColorShades = makeShadesFromPalette(primaryColors);

const neutralColors = {
  neutralBlue: makeColor('hsla(212, 29%, 88%, 1)'),
  neutralSand: makeColor('hsla(27, 23%, 91%, 1)'),
  neutralGrey: makeColor('hsla(0, 0%, 45%, 1)'),
  neutralGreyLight: makeColor('hsla(0, 0%, 71%, 1)'),
  neutralWhite: makeColor('hsla(0, 0%, 100%, 1)'),
  neutralBlack: makeColor('hsla(156, 10%, 10%, 1)'),
  darkYellow: makeColor('hsla(36, 100%, 53%, 1)'),
  lightOrange: makeColor('hsla(30, 84%, 84%, 1)'),
};

const neutralColorShades = makeShadesFromPalette(neutralColors);

const statusColors = {
  statusWarning: makeColor('hsla(30, 77%, 76%, 1)'),
  statusError: makeColor('hsla(2, 73%, 74%, 1)'),
  statusSuccess: makeColor('hsla(113, 48%, 72%, 1)'),
};

const statusColorShades = makeShadesFromPalette(statusColors);

const utilityColors = {
  transparent: 'transparent',
  currentColor: 'currentColor',
};

export const colors = {
  ...primaryColorShades,
  ...neutralColorShades,
  ...statusColorShades,
  ...utilityColors,
};

export type ThemeColor = keyof typeof colors;
