import { addPxToThemeValues } from '../utils';

const BorderRadiusValues = {
  none: 0,
  xs: 5,
  sm: 8,
  md: 16,
  lg: 20,
  xl: 50,
  xxl: 100,
};

export type BorderRadiusValue = keyof typeof BorderRadiusValues;

export const radii = addPxToThemeValues(BorderRadiusValues);
