import { colors } from './tokens/colors';
import { space } from './tokens/space';

// todo: add test

export const addPxToThemeValues = <T extends Record<string, number>>(themeValues: T) => {
  return Object.entries(themeValues).reduce(
    (prev, [key, value]) => ({
      ...prev,
      [key]: `${value}px`,
    }),
    {} as T
  );
};

export const color =
  <T2 extends typeof colors, T1 extends { theme: { colors: T2 } }>(v: keyof T2) =>
  (p: T1) =>
    p.theme.colors[v];

export const spacing =
  <T2 extends typeof space, T1 extends { theme: { space: T2 } }>(
    v1: keyof T2,
    v2?: keyof T2,
    v3?: keyof T2,
    v4?: keyof T2
  ) =>
  (p: T1) =>
    [v1, v2, v3, v4]
      .filter((v) => v != undefined)
      .map((v) => v && p.theme.space[v])
      .join(' ');

type BaseHSLColor<T extends string> = `hsla(${T})`;

type PrimaryColor = BaseHSLColor<`${string}, 1`>;

type ShadeName<TColor extends string> = `${TColor}${20 | 40 | 60 | 80}` | `${TColor}`;

export const makeColorShades = <TColor extends string>(
  name: TColor,
  color: PrimaryColor
): Record<ShadeName<TColor>, BaseHSLColor<string>> => {
  const colors = new Array(5).fill(0);

  return colors.reduce((prev, next, index) => {
    const shade = 100 - Math.round((index / colors.length) * 100);
    const colorShade = shade / 100;
    
    const newColor = color.replace(', 1)', `, ${colorShade.toString()})`) as BaseHSLColor<string>;

    const colorName = `${name}${shade !== 100 ? shade : ''}`;

    return {
      ...prev,
      [colorName]: newColor,
    };
  }, {} as Record<string, BaseHSLColor<string>>);
};

export const makeColor = (color: PrimaryColor): PrimaryColor => color;

export const makeShadesFromPalette = <TColor extends string>(
  palette: Record<TColor, PrimaryColor>
): Record<ShadeName<TColor>, BaseHSLColor<string>> => {
  return Object.entries(palette).reduce((prev, next) => {
    return {
      ...prev,
      ...makeColorShades(next[0], next[1] as PrimaryColor),
    };
  }, {} as Record<string, BaseHSLColor<string>>);
};
