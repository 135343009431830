import { breakpoints, colors, fonts, fontSizes, grid, keyframes, radii, shadows, sizes, space, zIndices } from './tokens';

// * https://styled-system.com/theme-specification/#key-reference

const Theme = {
  breakpoints,
  colors,
  fonts,
  fontSizes,
  space,
  radii,
  shadows,
  zIndices,
  grid,
  sizes,
  keyframes
};

export default Theme;
