import { keyframes as _keyframes } from 'styled-components';

const fadeIn = _keyframes`
    from { opacity: 0; }
    to   { opacity: 1; }
`;

const boop = _keyframes`
    from { transform: translateY(0); }
    to   { transform: translateY(-1px); }
`

const slideInFromRight = _keyframes`
    from { transform: translateX(100px); }
    to   { transform: translateX(0); }
`

export const keyframes = {
    fadeIn,
    boop,
    slideInFromRight
}