import { addPxToThemeValues } from '../utils';

const fontSizeValues = {
  '3xs': 10,
  '2xs': 12,
  xs: 14,
  sm: 16,
  md: 18,
  lg: 20,
  xl: 25,
  '2xl': 30,
  '3xl': 40,
  '4xl': 50,
  '5xl': 54,
};

export type FontSize = keyof typeof fontSizeValues

const fontSizes = addPxToThemeValues(fontSizeValues);

export { fontSizes };
