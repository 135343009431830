import { addPxToThemeValues } from '../utils';

const bareColWidth = {
  xs: 150,
  sm: 256,
  md: 352,
  lg: 544,
};

export type ColWidth = keyof typeof bareColWidth;

export const colWidth = addPxToThemeValues(bareColWidth);

export const grid = {
  colWidth,
};
